export enum ModeEnum {
    List, Edit, Create
};

export enum StatusEnum {
    Idle, Loading, Failed
};

export enum OperationEnum {
    None, Saving, Deleting,
};

export enum QuestionTypeEnum {
    Radio = 1, Text = 2, Textarea = 3, Checkbox = 4, Rating = 5, Ranking = 6
};

export enum RecommendationFactorEnum {
    Pay = 1, Career = 2, Empowerment = 3, Organization = 4, Security = 5, Relationships = 6, Leadership = 7, Alignment = 8, 
    //Informative = 9
}

export enum ReportTypeEnum {
    OneIndustryCompany = 1,
    OneOneDivisionIndustry = 2, 
    OneTwoCompanyLeadershipLevel = 3,
    OneThreeCompanyYearOverYear = 13,
    OneFourDivisionCompare = 14,
    OneFiveDivisionYearOverYear = 15,
    TwoCompanySelf = 20, 
    TwoCompanySelfDivision = 21, 
    TwoTwoCompanySelfLeadership = 22,
    TwoFourDivisionCompare = 24,
    ThreeCompanyBulgaria = 30,
}